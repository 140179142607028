import debounce from "lodash.debounce"
import moment from 'moment'

export function getCookieExpirationDate(days) {
  const expiration = new Date()
  expiration.setDate(expiration.getDate() + days)
  return expiration
}

export function truncateString(str, num) {
  if(!str) {
    return ''
  }

  if(str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export const customReactSelectStyles = {
  option: (provided, state) => {
    let color = '#222222'
    let background = '#ffffff'
    if(state.isSelected) {
      color = '#ffffff'
      background = '#666666'
    } else if(state.isFocused) {
      background = '#f7f7f7'
    }

    return {
      ...provided,
      color: color,
      background: background
    }
  },
  control: (provided, state) => ({
    ...provided,
    height: 40,
    minHeight: 40,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: state.isFocused ? 0 : provided.boxShadow,
    '&:hover': {
       border: '1px solid hsl(0,0%,80%)'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 40
  })
}

export function stringTimeConvert(string) {
  let output = string.match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [string];

  if (output.length > 1) {
    output = output.slice (1)
    output[5] = +output[0] < 12 ? ` ${ Ordering.translations.time_am }` : ` ${ Ordering.translations.time_pm }`
    output[0] = +output[0] % 12 || 12
    output[0] = output[0] < 10 ? `0${ output[0] }` : output[0]
  }
  return output.join('');
}

export function customReactSelectTheme(theme) {
  return {
    ...theme,
    colors: {
    ...theme.colors,
      primary50: '#f7f7f7'
    }
  }
}

export function blogImageUrls(imagesArray) {
  const blogImages = imagesArray.length > 0 ? imagesArray.map( image => {
      return {
        original: image.source,
        thumbnail: '',
        fullscreen: '',
      }
    }
    ) : [{
      original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
      thumbnail: '',
      fullscreen: ''
    }]
  return ( blogImages )
}

export function constructProductUrl(params) {
  let url = '/products?'

  for(let k in params) {
    if(params[k]) {
      url += `${k}=${params[k]}&`
    }
  }

  if(url[url.length-1] === '&') {
    url = url.slice(0, -1)
  }

  return url
}

export function constructBreadcrumbs(translations) {
  let pathArray = window.location.pathname.split('/').filter(function (el) { return el != "" });

  let breadcrumbs = []
  if (pathArray.length > 0) {
    breadcrumbs.push({title: translations.homepage.breadcrumb_title, href: `${window.location.protocol}//${window.location.host}`})

    if(pathArray.indexOf('category') > -1) {
      pathArray[pathArray.indexOf('category') + 1] = '-c-' + pathArray[pathArray.indexOf('category') + 1]
      pathArray = pathArray.filter(i => i !== 'category')
    }

    for (let i = 0; i < pathArray.length; i++) {
      if(pathArray[i].indexOf('-c-') == 0) {
        let category = pathArray[i].split('-c-')[1]
        breadcrumbs.push({title: category, href: `${breadcrumbs[i].href}/category/${category}`, last: i === pathArray.length - 1 })
      } else {
        let name =
          pathArray[i]
          if (pathArray[i] == 'products') {
            name = translations.tab_bar.products
          } else if (pathArray[i] == 'checkout') {
            name = translations.checkout.checkout_heading
          } else if (pathArray[i] == 'login') {
            name = translations.account.login_heading
          } else if (pathArray[i] == 'register') {
            name = translations.account.register_heading
          } else if (pathArray[i] == 'account') {
            name = translations.account.contact_info
          } else if (pathArray[i] == 'addresses') {
            name = translations.addresses.addresses
          } else if (pathArray[i] == 'orders') {
            name = translations.account.order_history
          } else if (pathArray[i] == 'privacy_policy') {
            name = translations.account.privacy_policy
          } else if (pathArray[i] == 'forgot_password') {
            name = translations.account.forgot_password_heading
          }
        breadcrumbs.push({title: name, href: `${breadcrumbs[i].href}/${pathArray[i]}`, last: i === pathArray.length - 1 })
      }
    }
  }

  return breadcrumbs
}

export function debounceEventHandler(...args) {
  const debounced = debounce(...args)
  return (e) => {
    if(typeof e.persist === 'function') e.persist()
    return debounced(e)
  }
}

export function constructProductsListUrl(params, categorySlug, locale = null) {
  const localePrefix = locale ? `/${locale}` : ''
  let url = `${localePrefix}/products`

  let objectKeys = []

  const formattedValues = objectKeys.map(value => {
    return value.join('+')
  }).join('++')

  const formattedKeys = objectKeys.join('+')

  if(categorySlug) {
    url += `/c/${categorySlug}`
  }

  if(formattedValues !== '' && formattedKeys !== '') {
    url += `/f/${formattedValues}/${formattedKeys}`
  }

  url += '?'

  for(let k in params) {
    if(params[k]) {
      url += `${k}=${params[k]}&`
    }
  }

  if(url[url.length-1] === '&') {
    url = url.slice(0, -1)
  }

  return url
}

export function formatPriceWithCurrency(amount, currency) {
  if(currency && currency.symbol_first) {
    return `${currency.symbol}${amount}`
  } else if(currency) {
    return `${amount} ${currency.symbol}`
  } else {
    return `€${amount}`
  }
}

export function urlParamsAsObject(queryString) {
  const urlParams = new URLSearchParams(queryString)
  const entries = urlParams.entries()

  let params = {}
  for(const entry of entries) {
    params[entry[0]] = entry[1]
  }

  return params
}

function rangeNumbers(from, to) {
  let result = []
  for (let i = from; i <= to; i++) {
    result.push(i)
  }
  return result
}

// EXISTS FOR FRONTEND COMPONENTS | DUPLICATE ON SHARED ADMIN PACKAGES
export function generateYoutubeLinkWithAttributes(videoId, videoSettings) {
  let link = `https://www.youtube.com/embed/${ videoId }?`
  Object.keys(videoSettings).map( attribute => {
    switch (attribute) {
      case 'showRelated':
        link += `&rel=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showCaptions':
        link += videoSettings[attribute] ? '&cc_load_policy=1&cc_lang_pref=en' : ''
        break
      case 'autoplay':
        link += `&autoplay=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'loop':
        link += videoSettings[attribute] ? `&loop=1&playlist=${ videoId }` : ''
        break
      case 'mute':
        link += `&mute=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'controls':
        link += `&controls=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showFullscreen':
        link += `&fs=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showLogo':
        link += `&modestbranding=${ !videoSettings[attribute] ? 1 : 0 }`
        break
    }
  })

  return link
}

export function getInitialTranslations(item) {
  let initialTranslations = {}
  item.translations.map( translation => {
    initialTranslations[translation.locale] = translation
  })

  return initialTranslations
}

export function getTranslatableField(entity, field, currentLocale, fallback = false) {
  const fallbackChar = '--'

  if(entity.translations) {
    const currentTranslatableFields = entity.translations.filter(t => t.locale === currentLocale)[0]
    const firstTranslatableFields = entity.translations.filter(t => t[field])[0]

    if(!fallback) {
      if(currentTranslatableFields) {
        return currentTranslatableFields[field] || fallbackChar
      } else {
        return fallbackChar
      }
    } else {
      if(currentTranslatableFields && currentTranslatableFields[field]) {
        return currentTranslatableFields[field] || fallbackChar
      } else if(firstTranslatableFields) {
        return firstTranslatableFields[field] || fallbackChar
      }
    }
  }

  return fallbackChar
}

export function constructTranslatableProductLink(entity, currentLocale, localeUrlPresent) {
  return `${localeUrlPresent ? '/' + currentLocale : ''}/products/${getTranslatableField(entity, 'slug', currentLocale)}`
}

export function deepClone(item) {
  return JSON.parse(JSON.stringify(item))
}

export function stringTimeToMoment(time) {
  let momentTime = time

  if (time.toString().length > 6) {
    momentTime = moment(time)
  } else {
    let formattedTime = new Date()
    formattedTime.setYear(2000)
    formattedTime.setMonth(0)
    formattedTime.setDate(1)
    formattedTime.setHours(time.split(':')[0], time.split(':')[1])
    formattedTime.setSeconds(0)
    momentTime = moment(formattedTime)
  }

  return momentTime
}

import React, { useState, useEffect, useRef, useMemo } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import cookie from 'react-cookies'
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'

import SalesBadge from '../products/SalesBadge'
import Header from './Header'
import Footer from './Footer'
import AddToCart from './AddToCart'
import CurrentOrder from './CurrentOrder'
import ProductItem from './ProductItem'

import { loadProductListAnalytics } from '../../helpers/analytics'

export default function QrMenu({
  appProps, categories, products, bundles,
  containerClass, setTablePath, getOrderPath, sendOrderPath
}) {
  const [currentCategory, setCurrentCategory]         = useState(categories[0])
  const [tabIndex, setTabIndex]                       = useState(0)
  const [tableNumber, setTableNumber]                 = useState(null)
  const [currentOrder, setCurrentOrder]               = useState(null)
  const [tableModal, setTableModal]                   = useState(false)
  const [showAddProductModal, setShowAddProductModal] = useState(false)
  const [productToAdd, setProductToAdd]               = useState(null)
  const [addToCartButton, setAddToCartButton]         = useState(false)
  const [lineItems, setLineItems]                     = useState(currentOrder ? currentOrder.lineItems : [])
  const [loading, setLoading]                         = useState(false)
  const [chooseLoader, setChooseLoader]               = useState(false)
  const [showSuccessModal, setShowSuccessModal]       = useState(false)

  const totalQuantity = useMemo(() => {
    return lineItems.reduce((a, b) => ({ quantity: a.quantity + b.quantity }), { quantity: 0 }).quantity
  }, [lineItems])

  const totalValue = useMemo(() => {
    let orderTotal = lineItems.reduce((total, li) => {
      return total += li.totalValue ? parseFloat(li.totalValue) : 0
    }, 0.0)
    return orderTotal ? parseFloat(orderTotal).toFixed(2) : 0
  }, [lineItems])

  const categoryItems = useMemo(() => {
    if (currentCategory) {
      return [
        ...products.filter(p => p.categoryIds.includes(currentCategory.id)),
        ...bundles.filter(b => b.categoryIds.includes(currentCategory.id))
      ]
    }
    return []
  }, [currentCategory])

  const currency = categoryItems.length > 0 && categoryItems[0].currency.symbol

  useEffect(() => {
    loadProductListAnalytics(categoryItems, appProps)
  }, [])

  useEffect( ()=> {
    if (cookie.load("tableNumber")) {
      setTableNumber(cookie.load("tableNumber"))
    }
  }, [tableNumber])

  useEffect( ()=> {
    if (cookie.load("orderNumber")) {
      getOrder()
    }
  }, [])

  useEffect(() => {
    setAddToCartButton(lineItems.length > 0)
  }, [lineItems])

  function setTable() {
    setChooseLoader(true)
    if (tableNumber < 1) {
      appProps.flashMessage.show( appProps.translations.choose_table_help, 'error')
    }

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    const storeUid = urlParams.get('store_uid')

    Rails.ajax({
      type: 'POST',
      url: `${setTablePath}?table_number=${tableNumber}&store_uid=${storeUid}`,
      dataType: 'json',
      success: res => {
        if (res.status === 404) {
          appProps.flashMessage.show(res.message, 'error')
          setChooseLoader(false)
        } else {
          appProps.flashMessage.show(res.message, 'success')
          if (res.order) {
            cookie.save('orderNumber', res.order.number, { path: '/' })
            cookie.save('tableNumber', res.table.api_uid, { path: '/' })
            setCurrentOrder(res.order)
            setAddToCartButton(res.order && res.order.lineItems.length > 0)
            setChooseLoader(false)
          }
        }
      }
    })
  }

  function getOrder() {
    setLoading(true)
    Rails.ajax({
      type: 'GET',
      url: `${getOrderPath}?order_number=${ cookie.load("orderNumber") }`,
      dataType: 'json',
      success: res => {
        if (res.order) {
          if (!res.order.completedAt) {
            setCurrentOrder(res.order)
            setLineItems(res.order.status === "draft" ? res.order.lineItems : [])
            setAddToCartButton(false)
          } else {
            resetInitialState()
          }
        }
        else if (res.message) {
          appProps.flashMessage.show(res.message, 'error')
          resetInitialState()
        }
        setLoading(false)
      },
      error: res => {
        if (res.errors) {
          setLoading(false)
          appProps.flashMessage.show(res.errors, 'error')
        }
      }
    })
  }

  function resetInitialState() {
    let expires = new Date()
    expires.setDate(expires.getDate() - 5)
    cookie.remove('orderNumber', { path: '/', expires })
    cookie.remove('tableNumber', { path: '/', expires })
    setCurrentOrder(null)
    setTableNumber(null)
    setAddToCartButton(false)
    setLineItems([])
  }

  function openAddProductModal(product) {
    setProductToAdd(product)
    setShowAddProductModal(true)
  }

  function closeAddProductModal() {
    setProductToAdd(null)
    setShowAddProductModal(false)
  }

  function deleteLineItem(lineItemUid) {
    if (lineItemUid.toString().startsWith('nli')) {
      setLoading(true)
      setLineItems(prevState => prevState.filter(li => li.uid != lineItemUid))
      setLoading(false)
    }
  }

  function sendOrder() {
    setLoading(true)
    let fd = new FormData()
    fd.append('order_number', currentOrder.number)
    fd.append('instance_uid', currentOrder.instanceUid || '')
    let _lineItems = [...currentOrder.lineItems, ...lineItems].map(lineItem => {
      return {
        ...(lineItem.bundle_id && { bundle_id: lineItem.bundle_id }),
        uid: lineItem.uid,
        quantity: lineItem.quantity,
        special_instructions: lineItem.specialInstructions,
        variants: lineItem.variants.map(variant => ({
          options: variant.selectedOptions,
          product_id: variant.productId
        })),
        statuses: lineItem.statuses.map(status => ({
          uid: status.uid,
          status: status.status,
          payment_method: status.payment_method
        }))
      }
    })
    fd.append('line_items', JSON.stringify(_lineItems))

    Rails.ajax({
      url: sendOrderPath,
      type: 'POST',
      data: fd,
      dataType: 'json',
      success: res => {
        if (res.order && !res.order.completedAt) {
          setCurrentOrder(res.order)
          setAddToCartButton(false)
          setShowSuccessModal(true)
          setLineItems(res.order.status === "draft" ? res.order.lineItems : [])
          setLoading(false)
          setTimeout(() => { setShowSuccessModal(false) }, 3500)
        } else {
          resetInitialState()
          setLoading(false)
        }
      },
      error: res => {
        if (['not_available', 'not_available_stock'].includes(res.errorCode)) {
          let notAvailableProductIds = res.errors.split(',')
          let notAvailableProducts = products.filter(pr => notAvailableProductIds.includes(String(pr.id)))
          notAvailableProducts.map(product => (
            appProps.flashMessage.show(`${ appProps.translations.qr_ordering[res.errorCode] } ${ product.presentation }`, 'error')
          ))
          setLoading(false)
        } else if (['order_updated', 'order_completed', 'order_locked'].includes(res.errorCode)) {
          appProps.flashMessage.show(appProps.translations.qr_ordering[res.errorCode], 'error')
          window.location.reload()
        } else if (res.errors) {
          appProps.flashMessage.show(res.errors, 'error')
          setLoading(false)
        }
      }
    })
  }

  return (
    <>
      <Header appProps={ appProps } />

      { currentOrder &&
        <CurrentOrder
          appProps={ appProps }
          currentOrder={ currentOrder }
          lineItems={ [...currentOrder.lineItems, ...lineItems] }
          currency={ currency }
          deleteLineItem={ deleteLineItem }
        />
      }

      <div className='main-container'>
        <div className={ containerClass }>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            { categories.length > 0 &&
              <TabList>
                { categories.map( (cat, index)=> {
                  return (
                    <Tab  className={` ${ index === tabIndex ? 'button' : 'button inverted'} `}
                          style={{ display: 'inline-block', marginRight: 10 }}
                          key={ cat.apiUid }
                          onClick={ ()=> setCurrentCategory(cat) }>
                          { cat.icon && <i style={{ marginRight: 5 }} className={`fa-light ${cat.icon}`} /> } { cat.presentation }
                    </Tab>
                  )
                })}
              </TabList>
            }

            { categories.map( (cat, index)=> {
              return (
                <TabPanel key={index} style={{ marginTop: 30 }}>
                  <div className="flex-box flex-column">
                    { categoryItems.map( (product, index)=>{
                      return (
                        <React.Fragment key={index}>
                          <ProductItem
                            appProps={ appProps }
                            product={ product }
                            openAddProductModal={ openAddProductModal }
                            canOrder={ true }
                          />
                          { showAddProductModal &&
                            <Modal
                              visible
                              mode='fullpage'
                              elementsClass='content-start'
                              theme='body-bg'
                              closeModal={ closeAddProductModal }
                            >
                              <AddToCart
                                appProps={ appProps }
                                product={ productToAdd }
                                lineItems={ lineItems }
                                setLineItems={ setLineItems }
                                closeModal={ closeAddProductModal }
                              />
                            </Modal>
                          }
                        </React.Fragment>
                      )
                    })}
                  </div>
                </TabPanel>
              )
            })}

          </Tabs>
        </div>
      </div>

      { !currentOrder &&
        <Modal
          visible
          mode='small'
          theme='body-bg'
          elementsClass='content-center'
          icon={ 'local_order' }
          acceptAction={ ()=> setTable() }
          saveText={ appProps.translations.qr_ordering.choose_table }
        >
          { chooseLoader ?
            <Loader size='medium' position='content-center items-center center' />
            :
            <>
              <p className='centered'>{ appProps.translations.qr_ordering.choose_table_help }</p>
              <input type='text' placeholder={ appProps.translations.qr_ordering.choose_table_placeholder } onChange={ (e)=> setTableNumber(e.target.value) } />
            </>
          }
        </Modal>
      }

      { showSuccessModal &&
        <Modal
          visible
          mode='small'
          elementsClass='content-center'
          icon={ 'check' }
          theme='body-bg'
        >
          <p className='centered'>{ appProps.translations.qr_ordering.send_order_success }</p>
        </Modal>
      }

      { addToCartButton &&
        <div className='box-shadow flex-box items-center content-end button' style={{ position: 'fixed', top: 0, left: 0, padding: 20, width: '100%', height: appProps.frontendStyles.headerHeight }}>
          <div className='button inverted flex-box items-center' onClick={ sendOrder }>
            <div style={{ marginRight: 10, padding: 4, color: '#222222' }}><b>{ `${ currency }${ totalValue }` }</b></div>
            <div>{ appProps.translations.qr_ordering.send_order }</div>
          </div>
        </div>
      }

      { loading && <Loader fullPage size='large'/> }
    </>
  )
}

import React, { useState, useEffect, useRef } from "react"
import { BankDetails, Loader } from '@reactiveonline/frontend_shared_components'

import CartItems from "../../cart/CartItems"
import CartTotals from "../../cart/CartTotals"
import Coupons from "../../checkout/Coupons"
import ProductPresentableTitle from "../../shared/ProductPresentableTitle"
import ProgressBar from '../../checkout/ProgressBar'

export default function OrderHistoryItem({ appProps, order }) {
  const [orderItem, setOrderItem] = useState(order)
  const [showOrder, setShowOrder] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(showOrder) {
      fetchOrder(orderItem.getOrderPath)
    }
  }, [showOrder])

  function fetchOrder(getOrderPath) {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: getOrderPath,
      dataType: "json",
      success: res => {
        if(res.order) {
          setOrderItem(res.order)
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className={`history_rows pointer ${showOrder ? 'active' : ''}`} onClick={ () => setShowOrder(prevState => !prevState) } >
        <div className="row date">
          <h6>{ orderItem.completedAtFormatted }</h6>
        </div>
        <div className="row number">
          <h6>{ orderItem.number }</h6>
        </div>
        <div className="row total">
          <h6>{ orderItem.totalWithAdjustments }</h6>
        </div>
        <div className="row delivered">
          <h6>
            { appProps.translations.orders[`status_${ orderItem.status }`] }
          </h6>
        </div>
        <div className="row actions content-end relative">
          { loading ?
            <Loader size={'medium'} />
            :
            <div className={`view ${ showOrder ? 'up_icon' : 'down_icon'}`} />
          }
        </div>
      </div>

      { showOrder && orderItem.line_items &&
        <div className="order-complete-wrapper order-history-item-wrapper">
          <div className="success">
            <h3 className="order-number">
               #{ orderItem.number }
            </h3>
          </div>

          <div className="order-info">
            <p className="address-details">
              { appProps.translations.addresses.delivery_address }: { orderItem.delivery_address.full_presentation }
            </p>
            <p>
              { appProps.translations.checkout.delivery_method }: { appProps.translations.checkout[`${orderItem.shipping_method}_title`] }
            </p>
            { orderItem.special_instructions &&
              <p>
                { appProps.translations.checkout.order_notes }: { orderItem.special_instructions }
              </p>
            }
            <p>
              { appProps.translations.checkout.payment_method }: { orderItem.payment_method }
            </p>
          </div>

          { orderItem.bankDetails && !!orderItem.bankDetails.length &&
            <BankDetails
              appProps={ appProps }
              bankDetails={ orderItem.bankDetails }
            />
          }

          { !!orderItem.expectedDeliveryTime && orderItem.progress !== 100 &&
            <ProgressBar
              appProps={ appProps }
              percent={ orderItem.progress }
            />
          }

          <div className="cart-wrapper">
            <div id="cart">
              <CartItems
                appProps={ appProps }
                order={ orderItem }
                disableRemove={ true }
                disableQuantityChanging={ true }
              />

              <CartTotals
                appProps={ appProps }
                order={ orderItem }
              />

              <Coupons
                appProps={ appProps }
                orderTotal={ orderItem.totalWithAdjustments }
                couponAdjustments={ orderItem.couponAdjustments }
                hideActions={ true }
              />
            </div>
          </div>
        </div>
      }
    </>
  )
}

import React, { useState, useEffect, useRef } from "react"
import Rails from "rails-ujs"
import ReactDOM from "react-dom"
import cookie from 'react-cookies'
import { Loader, Modal } from '@reactiveonline/frontend_shared_components'

import Addresses from "../account/screens/Addresses"
import ShippingMethods from "./ShippingMethods"
import PaymentMethods from "./PaymentMethods"
import CartItems from "../cart/CartItems"
import CartTotals from "../cart/CartTotals"
import HeaderIcons from "../header/HeaderIcons"
import Coupons from './Coupons'
import { InputField } from '@reactiveonline/frontend_shared_components'

export default function Checkout({
  appProps, csrfToken, newAddressPath, addressFormFields, paymentMethods, shippingMethods, googleApiKey, currentUser,
  userCoordinates, isOpen, stores, zones, order, checkoutStep, automationAccess, stripePublishableKey,
  orderId, type, legalTerms, phoneRequired, mobilePhoneRequired, cardsPath, setCurrentCardPath, checkoutDisabled
}) {

  const storesOpen = stores.filter(store => store.isOpen)
  const [selectedAddressId, setSelectedAddressId]                             = useState(order.bill_address_id)
  const [selectedShippingMethod, setSelectedShippingMethod]                   = useState(order.shipping_method ? order.shipping_method : (shippingMethods ? shippingMethods[0] : null))
  const [selectedPaymentMethodId, setSelectedPaymentMethodId]                 = useState(paymentMethods.length > 0 ? paymentMethods[0].id : null)
  const [notes, setNotes]                                                     = useState('')
  const [loading, setLoading]                                                 = useState(false)
  const [showPaymentIframe, setShowPaymentIframe]                             = useState(false)
  const [user, setUser]                                                       = useState(currentUser)
  const [selectedStoreId, setSelectedStoreId]                                 = useState(order.store_id || (storesOpen.length > 0 ? storesOpen[0].id : null))
  const [openStores, setOpenStores]                                           = useState(storesOpen)
  const [acceptedTerms, setAcceptedTerms]                                     = useState(false)
  const [showTerms, setShowTerms]                                             = useState(false)
  const [contactlessEnabled, setContactlessEnabled]                           = useState(false)
  const [deliveryFee, setDeliveryFee]                                         = useState(order.delivery_fee)
  const [deliveryFeeNoSymbol, setDeliveryFeeNoSymbol]                         = useState(order.deliveryFeeNoSymbol)
  const [updatedOrder, setUpdatedOrder]                                       = useState(order)
  const [submitStripeCard, setSubmitStripeCard]                               = useState(false)
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] = useState(null)
  const [stripePaymentIntentActionType, setStripePaymentIntentActionType]     = useState(null)
  const [stripeSetupIntentClientSecret, setStripeSetupIntentClientSecret]     = useState(null)
  const [stripeSetupIntentActionType, setStripeSetupIntentActionType]         = useState(null)
  const [couponAdjustments, setCouponAdjustments]                             = useState(order.couponAdjustments)
  const [totalWithAdjustments, setTotalWithAdjustments]                       = useState(order.totalWithAdjustments)
  const [pickupCustomerName, setPickupCustomerName]                           = useState(appProps.user ? appProps.user.fullname : '')
  const [pickupCustomerPhone, setPickupCustomerPhone]                         = useState('')
  const [pickupCustomerMobilePhone, setPickupCustomerMobilePhone]             = useState('')
  const [billingAddressRequired, setBillingAddressRequired]                   = useState(false)
  const [showUseDeliveryAsBillingSwitch, setShowUseDeliveryAsBillingSwitch]   = useState(false)
  const [useDeliveryAddressAsBilling, setUseDeliveryAddressAsBilling]         = useState(false)

  const customerNameField         = addressFormFields.find(f => f.name === "full_name")
  const customerPhoneField        = addressFormFields.find(f => f.name === "phone")
  const customerMobilePhoneField  = addressFormFields.find(f => f.name === "mobile_phone")

  const googleHasLoadedRef = useRef(false)
  const useConstructor = (callback = () => {}) => {
    const hasBeenCalled = useRef(false)
    if(hasBeenCalled.current) return
    callback()
    hasBeenCalled.current = true
  }

  useConstructor(() => {
    const script = document.createElement("script")
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places,geometry`
    script.async = true
    script.onload = () => { googleHasLoadedRef.current = true }
    document.body.appendChild(script)
  })

  useEffect(() => {
    checkAndUpdateCoupons(updatedOrder)
    if (automationAccess) {
      checkAutomations()
    }
  }, [])

  useEffect( () => {
    let _billingAddressRequired = paymentMethods.find(method => method.id == selectedPaymentMethodId).billingAddressRequired
    if (!_billingAddressRequired && selectedShippingMethod === 'pickup') {
      setSelectedAddressId(null)
    }
    setBillingAddressRequired(_billingAddressRequired)
  }, [selectedPaymentMethodId])

  useEffect( () => {
    if (billingAddressRequired && selectedShippingMethod == 'delivery') {
      setShowUseDeliveryAsBillingSwitch(true)
    }
    else {
      setShowUseDeliveryAsBillingSwitch(false)
    }
  }, [billingAddressRequired])

  useEffect(() => {
    if (selectedShippingMethod === "delivery") {
      selectShippingMethod(selectedShippingMethod)
    }
  }, [])

  useEffect( () => {
    if (useDeliveryAddressAsBilling) {
      setSelectedAddressId(order.delivery_address_id)
    }
  }, [useDeliveryAddressAsBilling])

  useEffect( ()=> {
    document.addEventListener("keydown", escFunction, false)
  },[])

  function escFunction(event){
    if (event.key === "Escape") {
      setShowTerms(false)
    }
  }

  function anyStoreIsOpen() {
    return openStores.length > 0
  }

  function selectShippingMethod(shippingMethod) {
    if(!anyStoreIsOpen()) {
      setSelectedShippingMethod(shippingMethod)
      return
    }
    setLoading(true)

    Rails.ajax({
      url: `/checkout/update_shipping_method`,
      type: 'POST',
      data: `checkout[user_id]=${user.id}&checkout[shipping_method]=${shippingMethod}`,
      success: res => {
        setSelectedShippingMethod(shippingMethod)
        setLoading(false)
        setDeliveryFee(res.order.delivery_fee)
        setDeliveryFeeNoSymbol(res.order.deliveryFeeNoSymbol)
        setUpdatedOrder(res.order)
        setCouponAdjustments(res.order.couponAdjustments)
        setTotalWithAdjustments(res.order.totalWithAdjustments)

        if(shippingMethod === 'pickup') {
          if(!openStores.filter(store => store.id === res.order.store_id)[0]) {
            setSelectedStoreId(openStores[0].id)
          }
        }

        if(shippingMethod == 'delivery'){
          setShowUseDeliveryAsBillingSwitch(true)
        }
        else {
          setShowUseDeliveryAsBillingSwitch(false)
        }

        checkAndUpdateCoupons(res.order)
      }
    })
  }

  function setStripeError() {
    setLoading(false)
    setSubmitStripeCard(false)
    setStripePaymentIntentClientSecret(null)
    setStripePaymentIntentActionType(null)
    setStripeSetupIntentActionType(null)
    setStripeSetupIntentClientSecret(null)
  }

  function completeOrder(e) {
    e.preventDefault()

    if(acceptedTerms) {
      setLoading(true)

      const selectedPaymentMethod = paymentMethods.filter(p => p.id === selectedPaymentMethodId)[0]
      const form = document.getElementById('checkout-form')

      if(selectedPaymentMethod.type === "StripePayment") {
        setSubmitStripeCard(true)
      } else {
        completeOrderRequest()
      }
    } else {
      appProps.flashMessage.show(appProps.translations.flash_messages.accept_terms_error, 'error')
    }
  }

  function completeOrderRequest(stripePaymentMethodId = null) {
    if (billingAddressRequired && !useDeliveryAddressAsBilling && !selectedAddressId) {
      appProps.flashMessage.show(appProps.translations.flash_messages.fill_all_required_fields, 'error')
      setLoading(false)
      return
    }
    setLoading(true)

    const form = document.getElementById('checkout-form')
    let formData = new FormData(form)

    if (selectedShippingMethod === 'pickup') {
      if (!billingAddressRequired) {
        if (!pickupCustomerName || (phoneRequired && !pickupCustomerPhone) || (mobilePhoneRequired && !pickupCustomerMobilePhone)) {
          appProps.flashMessage.show(appProps.translations.flash_messages.fill_all_required_fields, 'error')
          setLoading(false)
          return
        }
        formData.append('customer[full_name]', pickupCustomerName)
        formData.append('customer[phone]', pickupCustomerPhone)
        formData.append('customer[mobile_phone]', pickupCustomerMobilePhone)
      } else {
        formData.append('checkout[delivery_address_id]', selectedAddressId)
        formData.append('checkout[bill_address_id]', selectedAddressId)
      }
    } else {
      if (billingAddressRequired) {
        formData.append('checkout[bill_address_id]', useDeliveryAddressAsBilling ? order.delivery_address_id : selectedAddressId)
      } else {
        formData.append('checkout[bill_address_id]', order.delivery_address_id)
      }
      formData.append('checkout[delivery_address_id]', order.delivery_address_id)
    }

    const selectedPaymentMethod = paymentMethods.filter(p => p.id === selectedPaymentMethodId)[0]
    if(selectedPaymentMethod.type === "StripePayment") {
      formData.append('stripe_payment_method_id', stripePaymentMethodId)
    }

    Rails.ajax({
      url: '/checkout/complete',
      type: 'POST',
      dataType: 'json',
      accept: 'json',
      data: formData,
      success: res => {
        if(res.changedLineItems){
          res.changedLineItems.forEach(l => {
            appProps.flashMessage.show(`${l.title}: ${ appProps.translations.checkout.unavailable_quantity }`, 'error')
            setLoading(false)
          })
        }
        else {
          if(res.redirect_iframe_url) {
            setLoading(false)
            setShowPaymentIframe(true)
            document.getElementById('payment-iframe').src = res.redirect_iframe_url

          } else if (res.redirect_form) {
            document.body.innerHTML += res.redirect_form
            const rForm = document.getElementById('payment-redirect-form')
            rForm.submit()
            cookie.save('orderingMethod', '', { path: '/' })

          } else if (res.redirect_url) {
            cookie.save('orderingMethod', '', { path: '/' })
            window.location.href = res.redirect_url

          } else if((res.requires_action || res.requires_confirmation) && (res.payment_intent_client_secret || res.setup_intent_client_secret)) {
            if (res.payment_intent_client_secret) {
              setStripePaymentIntentActionType(res.requires_action ? 'requires_action' : 'requires_confirmation')
              setStripePaymentIntentClientSecret(res.payment_intent_client_secret)
              setStripeSetupIntentActionType(null)
              setStripeSetupIntentClientSecret(null)

              setSubmitStripeCard(false)
            } else {
              setStripePaymentIntentActionType(null)
              setStripePaymentIntentClientSecret(null)
              setStripeSetupIntentActionType(res.requires_action ? 'requires_action' : 'requires_confirmation')
              setStripeSetupIntentClientSecret(res.setup_intent_client_secret)

              setSubmitStripeCard(false)
            }

          } else if (res.couponsNotice) {
            couponsValidationAfterAction(res)
          } else {
            appProps.flashMessage.show(res.error, 'error')
            setLoading(false)
            setSubmitStripeCard(false)
          }
        }
      },
      error: res => {
        setLoading(false)
        setSubmitStripeCard(false)
      }
    })
  }

  function onDeleteLineItem(_order, isEmpty) {
    if (_order && !isEmpty && _order.line_items.length > 0) {
      setUpdatedOrder(_order)
      checkAndUpdateCoupons(_order)

      if (automationAccess) {
        checkAutomations()
      }
    } else {
      window.location.href = window.location.origin
    }
  }

  function onChangeLineItemQuantity(_order) {
    setUpdatedOrder(_order)
    checkAndUpdateCoupons(_order)

    if (automationAccess) {
      checkAutomations()
    }
  }

  function couponsValidationAfterAction(result) {
    result.messages.forEach( message => {
      if (message.type === 'failure') {
        appProps.flashMessage.show(`${ message.value } ${ result.deletedCoupons.join(', ') }`, 'error')
      } else if (message.type === 'notice') {
        appProps.flashMessage.show(`${ message.value } ${ result.deletedCoupons.join(', ') }`, 'notice')
      }
    })
    setCouponAdjustments(result.couponAdjustments)
    setTotalWithAdjustments(result.couponAdjustments)
    setLoading(false)
  }

  function checkAndUpdateCoupons(order) {
    if (order.couponAdjustments.length > 0) {
      setLoading(true)

      Rails.ajax({
        type: 'POST',
        url: '/checkout/check_and_update_coupons',
        dataType: 'json',
        success: res => {
          couponsValidationAfterAction(res)
        },
        error: res => {
          setLoading(false)
        }
      })
    }
  }

  function checkAutomations() {
    Rails.ajax({
      type: 'POST',
      url: appProps.automationsPath,
      dataType: 'json',
      success: res => {
        if (res.order) {
          setUpdatedOrder(res.order)
        }
      },
      error: res => {}
    })
  }

  const selectedPaymentMethod = paymentMethods.filter(p => p.id === selectedPaymentMethodId)[0]

  const showContactless = appProps.contactlessOptionAvailable && selectedShippingMethod === "delivery" && selectedPaymentMethod &&
                          selectedPaymentMethod.type !== "ReactiveCore::CashPayment" && selectedPaymentMethod.type !== "ReactiveCore::CardOnDeliveryPayment"

  const completeButton = anyStoreIsOpen() ?
    <div className="order-confirmation-wrapper">
      <div className="place-order-button-wrapper">
        <input
          type="button"
          value={ appProps.translations.checkout.place_order_button }
          className="button place-order-button"
          style={{'lineHeight': 0}}
          onClick={(e) => completeOrder(e)}
        />
      </div>
    </div> : null

  let selectedBillingAddress = user.addresses.filter(address => address.id === selectedAddressId)[0]

  return (
    <div className="main-container checkout-container">
      { (isOpen && !checkoutDisabled) ?
          <div className="checkout-wrapper">
            <div className="cart-wrapper card">
              <CartItems
                appProps={ appProps }
                order={ updatedOrder }
                csrfToken={ csrfToken }
                checkoutStep={ true }
                hideButton={ true }
                onDelete={ onDeleteLineItem }
                onChangeQuantity={ onChangeLineItemQuantity }
                setLoading={ setLoading }
                enableTracker={ false }
              />

              <CartTotals appProps={ appProps } order={ updatedOrder } />
              <Coupons
                appProps={ appProps }
                couponAdjustments={ couponAdjustments }
                orderTotal={ updatedOrder.totalWithAdjustments }
                setLoading={ setLoading }
              />
            </div>

            <div className="customer-info-wrapper">
              <div className="customer-info">
                <div className="customer-info-section addresses card">
                  <div className="section-heading">
                    <div className="heading-text">
                      { appProps.translations.shipping_method.summary }
                    </div>
                  </div>
                  <div className="flex-box items-center">
                    <div className="flex-box flex-1 items-center">
                      <div style={{ fontSize: 30, marginRight: 10 }} className={`icon ${ selectedShippingMethod === 'delivery' ? 'shipping-icon' : 'pickup-icon'}`} />
                      <div>{ appProps.translations.checkout[`${ selectedShippingMethod }_title`] }</div>
                    </div>
                  </div>
                </div>

                { paymentMethods.length > 0 &&
                  <div className="customer-info-section card">
                    <div className="section-heading">
                      <div className="heading-text">
                        { appProps.translations.checkout.choose_payment }
                      </div>
                    </div>
                    <PaymentMethods
                      appProps={ appProps }
                      paymentMethods={ paymentMethods }
                      onCheckoutSelect={ (method) => setSelectedPaymentMethodId(method.id) }
                      checkoutPaymentMethodSelectedId={ selectedPaymentMethodId }
                      submitStripeCard={ submitStripeCard }
                      setStripeError={ setStripeError }
                      stripePaymentIntentClientSecret = { stripePaymentIntentClientSecret }
                      stripePaymentIntentActionType={ stripePaymentIntentActionType }
                      stripeSetupIntentClientSecret = { stripeSetupIntentClientSecret }
                      stripeSetupIntentActionType={ stripeSetupIntentActionType }
                      stripePublishableKey={ stripePublishableKey ? stripePublishableKey : null }
                      completeOrder={ completeOrderRequest }
                      orderTotal={ updatedOrder.totalWithAdjustmentsValue }
                      orderId={ orderId }
                      type={ type }
                      cardsPath={ cardsPath }
                      setCurrentCardPath={ setCurrentCardPath }
                      setLoading={ setLoading }
                      stripeBillingDetails= {{
                        address: {
                          city: selectedBillingAddress ? selectedBillingAddress.extra.city : '',
                          country: selectedBillingAddress ? selectedBillingAddress.extra.country_alpha2_code : '',
                          line1: selectedBillingAddress ? selectedBillingAddress.extra.street : '',
                          postal_code: selectedBillingAddress ? selectedBillingAddress.extra.postal_code : '',
                          state: selectedBillingAddress ? selectedBillingAddress.extra.state : ''
                        },
                        email: order.email,
                        name: pickupCustomerName || (selectedBillingAddress ? selectedBillingAddress.extra.full_name : ''),
                        phone: selectedBillingAddress ? (selectedBillingAddress.phone || selectedBillingAddress.mobile_phone) : '',
                      }}
                    />
                  </div>
                }

                { showUseDeliveryAsBillingSwitch &&
                  <div className="flex-box items-center terms-checkbox switch-wrapper content-start">
                    <div className="terms-text switch-text">
                      { appProps.translations.checkout.use_delivery_as_billing }
                    </div>
                    <div className='flex-box'>
                      <label className="switch">
                        <input type="checkbox" name="acceptTerms" value="1" defaultChecked={ useDeliveryAddressAsBilling } style={{marginRight: 7}} onChange={() => {
                          setUseDeliveryAddressAsBilling(!useDeliveryAddressAsBilling)
                        }}/>
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                }

                { selectedShippingMethod === 'pickup' && !billingAddressRequired &&
                  <div className="customer-info-section card">
                    <div className="section-heading">
                      <div className="heading-text">
                        { appProps.translations.account.contact_info }
                      </div>
                    </div>

                    <div className="flex-box items-center">
                      <InputField
                        classes="flex-1"
                        label={ customerNameField.title }
                      >
                        <input
                          type="text"
                          value={ pickupCustomerName }
                          onChange={ (event) => setPickupCustomerName(event.target.value) }
                        />
                      </InputField>
                    </div>

                    { phoneRequired &&
                      <div className="flex-box items-center">
                        <InputField
                          classes="flex-1"
                          label={ customerPhoneField.title }
                        >
                          <input
                            type="text"
                            value={ pickupCustomerPhone }
                            onChange={ (event) => setPickupCustomerPhone(event.target.value) }
                          />
                        </InputField>
                      </div>
                    }

                    { mobilePhoneRequired &&
                      <div className="flex-box items-center">
                        <InputField
                          classes="flex-1"
                          label={ customerMobilePhoneField.title }
                        >
                          <input
                            type="text"
                            value={ pickupCustomerMobilePhone }
                            onChange={ (event) => setPickupCustomerMobilePhone(event.target.value) }
                          />
                        </InputField>
                      </div>
                    }

                  </div>
                }

                { billingAddressRequired && (!showUseDeliveryAsBillingSwitch || (showUseDeliveryAsBillingSwitch && !useDeliveryAddressAsBilling)) && googleHasLoadedRef.current &&
                  <Addresses
                    appProps={ appProps }
                    user={ user }
                    newAddressPath={ newAddressPath }
                    addressFormFields={ addressFormFields }
                    googleApiKey={ googleApiKey }
                    userCoordinates={ userCoordinates }
                    checkoutAddressSelectedId={ selectedAddressId }
                    pageTitle={ appProps.translations.account.billing }
                    checkoutAddressSelectedId={ selectedAddressId }
                    onCheckoutSelect={(address) => {
                      setSelectedAddressId(address.id)
                    }}
                    disableActions
                  />
                }

                <div className="customer-info-section card">
                  <div className="section-heading">
                    <div className="heading-text">
                      { appProps.translations.checkout.order_notes }
                    </div>
                  </div>
                  <textarea name="notes"
                            placeholder={ appProps.translations.checkout.notes_text }
                            onChange={(e) => {
                              setNotes(e.target.value)
                            }}
                            rows="4"
                            cols="50"
                            className="order-comments"/>
                </div>
              </div>

              <div className="additional-options">
                { showContactless &&
                  <div className="contactless-switch switch-wrapper">
                    <div className="switch-text">
                      <strong>{ appProps.translations.checkout.contactless_delivery_label }</strong>
                      <br/>
                      { appProps.translations.checkout.contactless_delivery_text }
                    </div>
                    <div>
                      <label className="switch">
                        <input type="checkbox" name="contactlessEnabled" value="1" defaultChecked={ contactlessEnabled } onChange={() => {
                          setContactlessEnabled(!contactlessEnabled)
                        }}/>
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                }

                <div className="flex-box items-center terms-checkbox switch-wrapper">
                  <div className="terms-text switch-text">
                    {`${appProps.translations.checkout.accept_terms_line_one} ${appProps.translations.checkout.place_order_button} ${appProps.translations.checkout.accept_terms_line_two}`}
                    <a onClick={() => setShowTerms(true) }>
                      { appProps.translations.general.terms_and_conditions }
                    </a>
                  </div>
                  <div className='flex-box'>
                    <label className="switch">
                      <input type="checkbox" name="acceptTerms" value="1" defaultChecked={ acceptedTerms } style={{marginRight: 7}} onChange={() => {
                        setAcceptedTerms(!acceptedTerms)
                      }}/>
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <form id={'checkout-form'} action='checkout/complete' method="post">
                <input type="hidden" name="authenticity_token" value={ csrfToken } />
                <input type="hidden" name="checkout[user_id]" value={ user.id } />
                <input type="hidden" name="checkout[shipping_method]" value={ selectedShippingMethod || ''} />
                <input type="hidden" name="checkout[payment_method_id]" value={ selectedPaymentMethodId || ''} />
                <input type="hidden" name="checkout[special_instructions]" value={ notes } />
                <input type="hidden" name="checkout[store_id]" value={ selectedStoreId } />
                {showContactless && <input type="hidden" name="checkout[contactless_delivery]" value={ contactlessEnabled } /> }

                { paymentMethods.length > 0 &&
                  <div>
                    { completeButton }
                  </div>
                }
              </form>

              { showPaymentIframe &&
                <div className="payment-iframe-overlay">
                  <iframe id="payment-iframe" src="" width="500" height="500"> </iframe>
                </div>
              }
            </div>

            { loading &&
              <Loader
                fullPage
                size='large'
              />
            }

            { showTerms &&
              <Modal
                visible
                mode='medium'
                closeModal={ ()=> setShowTerms(false) }
              >
                { legalTerms && <div dangerouslySetInnerHTML={{__html: legalTerms}} className="text"></div> }
              </Modal>
            }
          </div>
        :
        <div className="stores-closed">
          <div className="open-stores-container">
            <h4> { appProps.translations.checkout.inform_about_closed_stores }</h4>
          </div>
        </div>
      }
    </div>
  )
}
